import React, { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { APP_LOCALE } from '@/constants/define'
import PageMeta from '@/components/page-meta'

const Slogan: FC<{
  className?: string
}> = ({ className }) => {
  const { t } = useTranslation()
  return (
    <div className={classNames('text-gray-100', className)}>
      <h1
        className={`text-gold text-4xl md:text-7xl tracking-wider font-douyu text-center`}
        style={{
          lineHeight: 1,
          textShadow: '12px 2px #3B3010',
        }}
      >
        Gen.G Global Academy
      </h1>
      <div className="text-xl md:text-2xl mt-4 md:mt-8 text-center">
        {t('gga-slogan')}
      </div>
      <div className="text-base text-gold-600 md:text-lg mt-4 text-center">
        {t('gga-description')} - GGA
      </div>
    </div>
  )
}

const LoginLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <PageMeta />
      <div
        style={{ backgroundImage: 'url(/images/homepage/bg-logo.png)' }}
        className="h-screen bg-fixed bg-no-repeat bg-center bg-contain md:bg-auto flex flex-col md:flex-row items-center justify-center py-4 px-3 bg-darkjungle"
      >
        <div
          className={'flex-1 min-w-84 flex items-center justify-center py-4'}
        >
          <Slogan />
        </div>
        <div className="max-w-2xl md:px-16 md:py-12 h-full overflow-hidden flex flex-col justify-center">
          <div className={'h-auto overflow-auto hide-scroll'}>
            <div
              className={'w-84 py-4 px-3 rounded-md my-auto'}
              style={{
                backgroundColor:
                  APP_LOCALE !== 'zh_CN' ? '#fff' : 'rgba(51, 51, 51, 0.5)',
                boxShadow: '-6px 6px 6px 0px rgba(0, 0, 0, 0.16)',
                backdropFilter:
                  APP_LOCALE !== 'zh_CN' ? undefined : 'blur(32px)',
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginLayout
