import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import { withServerSideProps } from '@/containers/withServerSideProps'
import { AuthForm } from '@/components/zh_CN/auth-components'
import LoginLayout from '@/components/LoginLayout'

export default function Login() {
  const router = useRouter()
  const toPath = useCallback(
    path => {
      router.push(
        {
          pathname: path,
          query: router.query,
        },
        undefined,
        {
          shallow: true,
        }
      )
    },
    [router]
  )

  return (
    <AuthForm
      initialStage={'login'}
      onRegister={() => toPath('/register')}
      onSmsLogin={() => toPath('/login/sms')}
      onLogin={() => toPath('/login')}
      onReset={() => toPath('/forget-password')}
      onLogged={() => {
        router.reload()
      }}
    />
  )
}

Login.getLayout = LoginLayout
const getServerSideProps = withServerSideProps(undefined, [
  'checkAuthAndRedirect',
])

export { getServerSideProps }
